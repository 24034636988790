.report-assistant {
  position: relative;
  height: 100%;

  &__expander {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100001;
    background: transparent;
    border: 0;

    img {
      border-radius: 100%;
      width: 32px;
      height: 32px;
      cursor: pointer;
      border: 2px solid $level-2-border;
      filter: brightness(1);
      transform: scale(1);
      transition: transform 0.1s ease-in-out, filter 0.1s ease-in-out, border 0.1s ease-in-out;

      @include md {
        width: 64px;
        height: 64px;
      }

      &:hover {
        animation: spin 0.8s ease-in-out 1;
        transform: scale(1.05);
        filter: brightness(1.15);
        border: 2px solid $level-border-active;
      }
    }
  }

  &__container {
    position: fixed;
    z-index: 100000;
    top: $header-mobile-height;
    right: calc(-700px - $small-gap * 2);
    width: 700px;
    max-width: calc(100vw - $small-gap * 2 - 18px);
    padding: $content-padding $small-gap;
    height: calc(100% - 2 * $content-padding);
    max-height: calc(100vh - $header-mobile-height - 2 * $content-padding - 24px);
    overflow-y: auto;
    @include restyled-scroll-bar;
    background: $level-1-bg;
    transition: right 0.2s ease-in-out;

    @include md {
      top: var(--header-desktop-height);
      max-height: calc(100vh - var(--header-desktop-height) - 2 * $content-padding - 12px);
    }

    &--expanded {
      right: 0;
      border-left: 1px solid $level-2-border;
      border-bottom: 1px solid $level-2-border;
    }
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__close-button {
    position: absolute;
    top: $small-gap;
    right: $small-gap;
    padding: $tiny-gap $small-gap;
  }

  &__response-container {
    position: relative;
    padding-top: 24px;
  }

  &__code-container {
    position: relative;

    pre {
      padding: 0 !important;
      padding-bottom: 1em !important;
    }
  }

  &__copy-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__rating-button {
    &--active {
      background: $level-2-bg;
      border: $level-2-border;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  15% {
    transform: rotate(-20deg) scale(0.95);
  }
  70% {
    transform: rotate(380deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1.05);
  }
}
