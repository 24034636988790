$archon: true;
@import "_variables";
@import "breakpoints";

body.theme {
  &--v3 {
    background: $level-0-bg-no-gradient;
    font-family: $segoeUi;

    #__next {
      background: $level-0-bg-no-gradient;
    }
  }
}

html.theme {
  &--v3 {
    background: $level-0-bg-no-gradient;
    font-family: $segoeUi;
  }
}


html.takeover-active > body { max-width:1070px !important; margin:0 auto!important; }

#playwire-video-container { position:relative; width:400px; min-height:225px; margin-top:10px; margin-bottom:10px; border:1px solid #555555 !important; z-index:1; background-color:black; display:flex;flex-direction:column; justify-content:center }

@media (max-width:900px) {
  #playwire-video-container { display:none !important; }
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
}

body {
  --header-desktop-height: #{$header-top-height + $header-bottom-height};
  min-height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  line-height: 1.4;
  padding: var(--header-desktop-height) 0 0 0;
  margin: 0;
  background-color: black;
  color: rgb(225, 242, 245);
  font-size: 13px;

  @media (max-width: 768px) {
    padding-top: 56px;

    &.header-is-open {
      overflow-y: hidden;
    }
  }

  &.header-footer-hidden {
    padding-top: 0;
  }
}

#wrapper {
  min-width: 1024px;
  min-height: 100%;
  position: relative;
}

#main-menu {
  border: none;
  border-bottom: 1px solid #555555;
  position: fixed;
  top: 0;
  width:100%;
  z-index: 11000;
  padding: 0;
  font-size: 14px;
  height: 52px;
}

body.flex-leaderboard-active #main-menu { border-top:1px solid #555555; }

body.flex-leaderboard-active.flex-leaderboard-docked #right-ad-box { top: 170px !important; }

body.flex-leaderboard-active .sidebar
{
  z-index: 100 !important;
  top:403px;
}

body.flex-leaderboard-active.flex-leaderboard-docked .sidebar
{
  z-index: 100 !important;
  top:153px;
}

html.takeover-active #main-menu { max-width:1070px !important; }

#main-menu a {
  font-size: 14px !important;
}

#main-menu ul {
  z-index: 11000;
}

.right {
  float: right !important;
}

#header-image {
  padding-top: 5px
}

.tagline {
  font-family: Avenir, Arial, sans-serif;
  font-size: 12px;
  margin-top: -15px;
  margin-left: 5px;
  float: left;
}

a:link, a:visited, .link-color {
  color: rgb(70, 197, 255);
  text-decoration: none
}

a.disabled {
  cursor: default;
  pointer-events: none;
  font-style: italic;
  color: rgb(31, 89, 117);
}

body.swagger-section {
  background: #fff !important;
  color: black !important;
  padding-top: 53px !important;
}

body, .ui-widget {
  font-family: Avenir, Arial, sans-serif !important;
}

#content {
  width: 1024px;
  margin: 10px auto;
  position: relative;
}

.responsive #content {
  width: auto;
  max-width: 1024px;
  padding-left: 10px;
  padding-right: 10px;
}

.responsive #content.uncapped {
  max-width: initial;
  padding-left: 0;
  padding-right: 0;
  margin: 10px 0;
}

.dialog-block {
  background: none #001020 !important;
  margin-bottom: 1em;
  border: 1px solid #555555;
}

body:not(.swagger-section) h2:not(.heading) {
  font-weight: bold;
  font-size: 20px;
  text-shadow: 1px 1px 1px #000;
}

.dialog-title {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#161616), to(#0A0A0A));
  background: -webkit-linear-gradient(top, #161616, #0A0A0A);
  background: -moz-linear-gradient(top, #161616, #0A0A0A);
  background: -o-linear-gradient(top, #161616, #0A0A0A);
  background: -ms-linear-gradient(top, #161616, #0A0A0A);
  background: linear-gradient(to bottom, #161616, #0A0A0A);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 1px #000;
  padding-left: 1em;
  color: rgb(225, 242, 245);
  font-weight: 100;
  font-size: 20px;
  line-height: 1.6;
  border-bottom: 1px solid #555555;
}

.dialog-table {
  margin: 10px auto;
}

.dialog-content {
  padding: 5px !important;
}

input:disabled {
  opacity: 0.4
}

input, select, textarea {
  font: inherit !important;
  line-height: 1.3 !important;
}

.ui-state-error {
  background: none #200000 !important;
  color: rgb(225, 242, 245) !important;
  border-color: #800000 !important;
}

.status-box {
  background: none #001040 !important;
  color: rgb(225, 242, 245) !important;
  border: 1px solid #808080;
  margin: 5px;
  padding: 5px;
  border-radius: 5px 5px;
}

#dialog-confirm {
  background: black !important;
}

.ui-dialog-buttonset .ui-button, .ui-dialog-titlebar-close {
  outline-style: none !important;
}

input:focus, textarea:focus {
  outline: none !important;
}

input, textarea, select {
  background-color: rgb(225, 242, 245) !important;
  color: black !important;
  border: 1px solid black;
  border-radius: 5px !important;
  padding-left: 5px;
  line-height: 1.4 !important
}

input[type=color] {
  background-color: transparent !important;
  padding-left: 2px;
  height: 36px;
}

#searchfield {
  opacity: 0.8;
  font-size: 14px !important
}

input[type=button], input[type=submit] {
  border-radius: 5px !important;
  background-color: rgb(102, 153, 204) !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(102, 153, 204)), to(rgb(70, 121, 172)));
  background: -webkit-linear-gradient(top, rgb(102, 153, 204), rgb(70, 121, 172));
  background: -moz-linear-gradient(top, rgb(102, 153, 204), rgb(70, 121, 172));
  background: -o-linear-gradient(top, rgb(102, 153, 204), rgb(70, 121, 172));
  background: -ms-linear-gradient(top, rgb(102, 153, 204), rgb(70, 121, 172));
  background: linear-gradient(to bottom, rgb(102, 153, 204), rgb(70, 121, 172));
  font-size: 12px !important;
  font-family: Avenir, Arial, sans-serif;
  color: rgb(225, 242, 245) !important;
  border: none;
  margin: 0 2px;
  padding: 2px 10px;
  height: 25px;
  box-sizing: border-box;
  cursor: pointer;
}

input[type=file]::-webkit-file-upload-button {
  border-radius: 10px !important;
  background: -webkit-linear-gradient(top, rgb(0, 32, 48), rgb(0, 64, 80));
  font-size: 12px !important;
  font-family: Avenir, Arial, sans-serif;
  color: rgb(225, 242, 245) !important;
  border: none;
  margin: 1px 0;
}

input[type=button]:hover:enabled, input[type=submit]:enabled:hover, input[type=file]:hover::-webkit-file-upload-button {
  background-color: rgb(94, 145, 196) !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(94, 145, 196)), to(rgb(62, 113, 164)));
  background: -webkit-linear-gradient(top, rgb(94, 145, 196), rgb(62, 113, 164));
  background: -moz-linear-gradient(top, rgb(94, 145, 196), rgb(62, 113, 164));
  background: -o-linear-gradient(top, rgb(94, 145, 196), rgb(62, 113, 164));
  background: -ms-linear-gradient(top, rgb(94, 145, 196), rgb(62, 113, 164));
  background: linear-gradient(to bottom, rgb(94, 145, 196), rgb(62, 113, 164));
}

#footer-spacer {
  clear: both
}

#footer {
  font-size: 12px;
  padding: 15px 0;
  text-align: center;
  color: #aaaaaa;
  background: none !important;
  border: none !important;
}

#footer > a:link, #footer > a:visited {
  color: #aaaaaa;
}

.ui-autocomplete {
  text-shadow: none;
  font: inherit;
  z-index: 100000 !important;
  background: black !important
}

.ui-autocomplete * {
  border: 0 !important;
}

.ui-menu-item-wrapper:hover, .ui-menu-item-wrapper.ui-state-active {
  color: white;
  background: #000040 !important;
}

.ui-menu-item-wrapper {
  font-size: 14px;
  padding: 3px;
}

.dialog-content {
  min-height: 630px;
}

.responsive .dialog-content {
  min-height: 30vh;
}

.anbu {
  color: black; /* Laravel's DB Profiler Bar */
}

.bnet-image {
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
  border: 1px solid #a0a0a0;
}

.bnet-tab {
  float: left;
  padding: 3px;
  min-width: 63px;
  border: 1px solid #a0a0a0;
  color: #a0a0a0 !important;
  background-color: black;
  text-align: center;
  margin-bottom: 2px;
}

.bnet-tab:not(:last-child) {
  margin-right: 5px;
}

a.bnet-tab:hover {
  color: white !important;
  background-color: #000040;
}

.dt-buttons {
  text-align: right;
  padding-top: 5px;
}

.dt-button {
  color: white !important
}

.Zone, .factionless {
  color: white !important;
}

.Boss, .Boss.do-not-change-color-on-hover:hover {
  color: #B4BDFF !important
}

.Boss-bg, .Boss-behind-text {
  background-color: #B4BDFF !important
}

.Boss:hover {
  color: #FFCDFF !important
}

.NPC, .NPC.do-not-change-color-on-hover:hover {
  color: #84BDF8 !important
}

.NPC-bg, .NPC-behind-text {
  background-color: #84BDF8 !important
}

.NPC:hover {
  color: #A4DDFF !important
}

.Pet, .Pet.do-not-change-color-on-hover:hover {
  color: #64BDC8 !important
}

.Pet-bg, .Pet-behind-text {
  background-color: #64BDC8 !important
}

.title-yellow {
  color: #ff8;
}

.bnet-char-tab {
  float: left;
  width: 180px;
  border: 1px solid #a0a0a0 !important;
  padding: 1px;
  margin: 4px;
  line-height: 1.3;
  background-color: black;
}

.bnet-char-tab:hover {
  background-color: #000040
}

.bnet-char-pic {
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 7px;
}

.bnet-char-name-container {
  display: block;
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.bnet-char-name {
  font-size: 16px;
  font-weight: bold;
}

.ellipsize {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.ui-menu-item > a.ui-state-focus {
  background: #000040 !important;
  color: rgb(70, 197, 255) !important;
}

.main-table-percent {
  display: inline-block;
  text-align: right;
  width: 50px;
}

.active-percent, .uptime-percent, .num {
  text-align: right;
  white-space: nowrap
}

.summary-table {
  cursor: default
}

.rank-table {
  font-size: 13px;
  line-height: 20px;
  width: 100%
}

.rank-inset {
  margin: 1em
}

.main-table-number, .main-table-miss {
  text-align: right;
}

.main-table-name {
  min-width: 90px;
  padding-left: 1px !important;
}

.icons-present {
  padding-left: 1px !important;
}

.icon-not-found {
  padding-left: 22px !important;
}

#graph-loading {
  position: absolute;
  top: 170px;
  left: 25%;
  right: 25%;
  text-align: center;
  z-index: 100;
  background-color: #141414;
  border-radius: 2px;
  border: 1px solid #333;
}

#event-killer {
  opacity: 0;
  z-index: 1000;
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
}

#table-wrapper {
  overflow: hidden;
  margin-bottom: 0
}

/* FIXME: Remove once tables converted. */
.table-wrapper {
  overflow: hidden;
  margin-bottom: 0
}

.dataTable th {
  background-image: none !important;
}

.DataTables_sort_icon {
  display: none !important
}

.fg-toolbar {
  display: none !important;
}

th {
  outline-style: none !important;
  cursor: default;
  padding: 2px
}

.summary-table {
  border-collapse: collapse;
  background-color: black;
  border-radius: 0;
  font-size: 13px;
  line-height: 20px;
}

.tpd-tooltip .summary-table {
  font-size: inherit;
  line-height: inherit
}

.summary-table td {
  padding: 2px 6px 2px;
  border: 1px solid #333333;
}

.summary-table th {
  padding: 2px 6px 2px;
}

tr.even {
  background-color: #101010;
}

tr.totals td {
  background-image: none !important;
  background-color: #202020 !important;
  border: 1px solid #333333 !important;
}

tr.odd:hover td, tr.even:hover td, tr.selected {
  background-color: #002040 !important;
}

.rank {
  text-align: right;
  width: 35px;
  max-width: 35px;
  min-width: 35px
}

.not-best {
  font-style: italic
}

.artifact {
  color: rgb(229, 204, 128) !important
}

.legendary {
  color: rgb(255, 128, 0) !important
}

.astounding {
  color: rgb(226, 104, 168) !important;
}

.magnificent {
  color: rgb(190, 130, 0) !important;
}

.epic {
  color: rgb(163, 53, 238) !important
}

.rare {
  color: rgb(0, 112, 255) !important
}

.uncommon {
  color: rgb(30, 255, 0) !important
}

.uncommon-muted {
  color: #48c238 !important;
}

.common {
  color: #666 !important
}

.common-white {
  color: $text-white !important
}

.artifact-bg {
  background-color: rgb(229, 204, 128) !important;
}

.astounding-bg {
  background-color: rgb(226, 104, 168) !important;
}

.legendary-bg {
  background-color: rgb(255, 128, 0) !important
}

.epic-bg {
  background-color: rgb(163, 53, 238) !important
}

.rare-bg {
  background-color: rgb(0, 112, 255) !important
}

.uncommon-bg {
  background-color: rgb(15, 128, 0) !important
}

.common-bg {
  background-color: #cccccc !important
}

.exploit {
  background-color: rgb(42, 41, 10) !important
}

.exploit-fg {
  color: rgb(104, 102, 30) !important;
}

.banned {
  background-color: rgb(42, 0, 0) !important
}

.banned-fg {
  color: rgb(154, 0, 0) !important;
}

.filter-bar .menu .dropdown_1column, .filter-bar .menu .col_1 {
  min-width: 100%;
}

.master-autocomplete-item {
  display: flex;
  margin: 3px;
  align-items: baseline
}

.master-autocomplete-item .label {
  width: 45%;
}

.master-autocomplete-item .server {
  flex: 1;
}

.master-autocomplete-item .type {
  color: #a0a0a0;
  font-size: 11px;
  margin-left: 5px;
}

.table-icon {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 6px;
  --size: 20;
}

.kill {
  color: rgb(50%, 90%, 40%) !important
}

.kill:hover {
  color: rgb(65%, 90%, 55%) !important
}

.inprogress {
  color: #ff8 !important;
}

.wipe {
  color: rgb(90%, 50%, 40%) !important;

  .shrouded {
    display: flex;

    img {
      margin-right: 0.5em;
    }
  }
}

.wipe:hover {
  color: rgb(90%, 65%, 55%) !important
}

.pagination {
  display: flex;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0
}

.pagination > li {
  margin: 0 3px
}

.estimate {
  color: #ff8;
}

#report-rankings-export {
  font-size: 10px;
  margin: 1em 1em 3em;
}

/* Physical */
.school-1, .GeargrinderBiker {
  color: rgb(229, 204, 128) !important;
}

/* Holy */
.school-2, .BlessingOfTheSacredFlame {
  color: rgb(100%, 100%, 56%) !important;
}

/* Fire */

.school-4 {
  color: rgb(92%, 27%, 38%) !important;
}

/* Nature */
.school-8, .RollingRubbish, .GigaControls, .StormrookFeather {
  color: rgb(82%, 98%, 60%) !important;
}

/* Frost */

.school-16 {
  color: rgb(29%, 50%, 100%) !important;
}

/* Shadow */

.school-32, .school-36 {
  color: rgb(72%, 66%, 94%) !important;
}

/* Arcane */
.school-64 {
  color: rgb(56%, 95%, 100%) !important;
}

/* Chaos */
.school-127, .school-124, .school-126, .school-125 {
  color: rgb(163, 48, 201) !important;
}

/* Earth in FF */
.school-128 {
  color: rgb(100%, 64%, 29%) !important;
}

/* Limit Break in FF */
.school-256, .school-1024 {
  color: #2599be !important;
}

/* Air in FF */
.school-512 {
  color: rgb(75%, 75%, 75%) !important;
}


/* Physical */
.school-1-bg, .GeargrinderBiker-bg {
  background-color: rgb(229, 204, 128) !important
}

/* Holy */
.school-2-bg, .BlessingOfTheSacredFlame-bg {
  background-color: rgb(100%, 100%, 56%) !important;
}

/* Fire */

.school-4-bg {
  background-color: rgb(92%, 27%, 38%) !important;
}

/* Nature */
.school-8-bg, .RollingRubbish-bg, .GigaControls-bg, .StormrookFeather-bg {
  background-color: rgb(82%, 98%, 60%) !important;
}

/* Frost */

.school-16-bg {
  background-color: rgb(29%, 50%, 100%) !important;
}

/* Shadow */

.school-32-bg {
  background-color: rgb(72%, 66%, 94%) !important;
}

/* Shadowflame */
.school-36-bg {
  background-image: linear-gradient(45deg,rgb(72%, 66%, 94%), rgb(92%, 27%, 38%)) !important;
}

/* Chromatic, fire -> frost -> arcane -> nature -> shadow */
.school-62-bg {
  background-image: linear-gradient(45deg, rgb(92%, 27%, 38%), rgb(29%, 50%, 100%), rgb(56%, 95%, 100%), rgb(82%, 98%, 60%), rgb(72%, 66%, 94%))
}

/* Arcane */
.school-64-bg {
  background-color: rgb(56%, 95%, 100%) !important;
}

/* Chaos */
.school-127-bg, .school-124-bg, .school-125-bg {
  background-color: #a330c9 !important;
}

/* Earth in FF */
.school-128-bg {
  background-color: rgb(100%, 64%, 29%) !important;
}

.school-256-bg, .school-1024-bg {
  background-color: #2599be !important;
}

/* Air in FF */
.school-512-bg {
  background-color: rgb(75%, 75%, 75%) !important;
}

/* Physical */
.school-1-behind-text {
  background-color: rgb(50%, 32%, 8%) !important;
}

/* Holy */
.school-2-behind-text {
  background-color: rgb(50%, 50%, 28%) !important;
}

/* Fire */

.school-4-behind-text {
  background-color: rgb(46%, 13%, 19%) !important;
}

/* Nature */
.school-8-behind-text {
  background-color: rgb(41%, 49%, 30%) !important;
}

/* Frost */

.school-16-behind-text {
  background-color: rgb(14%, 25%, 50%) !important;
}

/* Shadow */

.school-32-behind-text, .school-36-behind-text {
  background-color: rgb(36%, 33%, 47%) !important;
}

.school-62-behind-text {
  background-color: #c0c0c0 !important;
}

/* Arcane */
.school-64-behind-text {
  background-color: rgb(28%, 47%, 50%) !important;
}

/* Chaos */
.school-127-behind-text, .school-124-behind-text {
  background-color: #631089 !important;
}

/* Earth in FF */
.school-128-behind-text {
  background-color: rgb(60%, 33%, 6%) !important;
}

.school-256-behind-text, .school-1024-behind-text {
  background-color: #125580 !important;
}

/* Air in FF */
.school-512-behind-text {
  background-color: rgb(37%, 37%, 37%) !important;
}

.Count-bg {
  background-color: rgb(220, 220, 220);
}

.darken-bg {
  background-color: rgba(0, 0, 0, 0.5);
}

.primary {
  color: rgb(82%, 98%, 60%)
}

.rdps {
  color: rgb(82%, 60%, 98%)
}

.adps {
  color: rgb(40%, 70%, 100%)
}

.ndps {
  color: #ffa070;
}

.cdps {
  color: #ffffa0;
}

#summary {
  position: relative;
  z-index: 1;
  max-width: 1024px
}

.filter-bar, .caption-miniature {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.caption-miniature {
  border: 1px solid #555;
  margin-bottom: -1px;
}

.filter-bar.miniature, .caption-miniature {
  background-color: rgb(20, 20, 60);
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(12, 12, 40)), to(rgb(18, 18, 50)));
  background: -webkit-linear-gradient(bottom, rgb(12, 12, 40), rgb(18, 18, 50));
  background: -moz-linear-gradient(bottom, rgb(12, 12, 40), rgb(18, 18, 50));
  background: linear-gradient(to top, rgb(12, 12, 40), rgb(18, 18, 50));
  font-size: 13px;
  padding: 5px;
  border-bottom: 1px solid #555;
}

.filter-bar-menu {
  padding: 0;
}

.filter-bar-menu li {
  font-size: 12px !important
}

#advanced-filter {
  float: right;
  line-height: 21px;
  padding-top: 6px;
  margin-right: 10px;
}

#advanced-filter {
  color: white
}

#advanced-filter:hover {
  color: rgb(70, 197, 255)
}

.filter-item {
  color: rgb(225, 242, 245) !important
}

.filter-item:hover {
  color: rgb(70, 197, 255) !important
}

.side-rail-ads {
  display: flex;
  flex-direction: column;
  gap: $small-gap;
  min-height:600px;
  min-width:160px;
  max-width:300px;
  overflow:hidden;
  position:-webkit-sticky;
  position:sticky;
  top: calc(var(--header-desktop-height) + 10px);
}

.side-rail-ads--home {
  margin: 10px 10px 0 0;
  min-width:300px;
  max-width:300px;
}
@media (max-width:1100px) {
  .side-rail-ads--home { display:none; }
}

#rankings-banner {
  min-height: 90px
}

#super-viewer-banner {
  box-sizing: border-box;
  border: 4px solid red;
  margin-bottom: 6px;
  padding: 6px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: $body-font-size;
  position: relative;
}

.silver {
  color: silver !important
}

.gold {
  color: gold !important
}

.platinum {
  color: #ff8 !important
}

.alchemical-society {
  color: #b93cff !important;
}

.white {
  color: white !important
}

#login-menu {
  margin-top: 10px
}

#searchfield-menu {
  min-width: 35%;
  margin-top: 10px
}

.searchbutton-mini {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  width: 28px;
  background-color: #002040;
  border-left: 1px solid #555555;
}

.searchbutton-mini-image {
  height: 100%;
}

#searchfield-compact-menu {
  display: none;
  padding: 0 !important;
  margin-top: 10px
}

#searchfield-compact-menu a {
  padding: 0 !important
}

#searchfield-compact-image {
  height: 36px;
  display: block;
  margin-top: -3px;
}

.compact-search #searchfield-menu {
  display: none
}

.compact-search #searchfield-compact-menu {
  display: block;
}

#lang-switcher-menu {
  margin-top: 10px
}

@media (max-width: 768px) {
  #rankings-banner {
    min-height: 50px
  }
}

@media (max-width: 500px) {
  #home-menu, #lang-switcher-text {
    display: none
  }

  #searchfield-compact-menu {
    display: block
  }
  #searchfield-menu {
    display: none
  }

  #footer {
    font-size: 8px !important;
  }
}

.ui-autocomplete {
  border-radius: 0 !important;
}

#home-menu a {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

#home-logo {
  width: 42px;
  margin-top: 5px
}

#zones-and-realms-toggle {
  float: left;
  overflow: hidden;
  margin: 16px 16px 5px 6px;
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#zones-and-realms-toggle span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#zones-and-realms-toggle.open {
  top: -1px
}

#zones-and-realms-toggle span:nth-child(1) {
  top: 0px;
}

#zones-and-realms-toggle span:nth-child(2) {
  top: 7px;
}

#zones-and-realms-toggle span:nth-child(3) {
  top: 14px;
}

#zones-and-realms-toggle.open span:nth-child(1) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  left: 2px;
  top: 16px;
}

#zones-and-realms-toggle.open span:nth-child(2) {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

#zones-and-realms-toggle.open span:nth-child(3) {
  left: -2px;
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.sidebar {
  visibility: hidden;
  position: fixed;
  background: #141414;
  top: 53px;
  bottom:0;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  z-index: 100000;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 15px;
}

.sidebar.open {
  visibility: visible
}

.sidebar.top-bar {
  position: fixed;
  left: 2px;
  right: 2px;
  top: 53px;
  background: transparent;
  overflow: visible
}

.login-button, #lang-switcher-text {
  color: white !important
}

.faction-class-container {
  display: flex;
}

.faction-class-item > img {
  border: 1px solid #555555;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.faction-class-item {
  padding: 5px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  white-space: nowrap !important;
  cursor: pointer
}

.faction-class-container a {
  white-space: nowrap !important;
  min-width: 180px;
}

.sidebar-item {
  position: relative;
  clear: both;
}

.sidebar-line {
  clear: both;
  position: relative;
  padding: 10px;
  margin-bottom: 2px;

  &:not(.noHover) {
    cursor: pointer;
  }
}

.sidebar-line.noHover {
  .team-entry {
    pointer-events: none;
  }
}

.sidebar-line.noInteraction {
  pointer-events: none;
}

.sidebar-line:not(.noHover):hover {
  background-color: #002040 !important;
  color: white
}

.sidebar-item-toggle {
  float: left;
  margin-right: 7px;
  visibility: hidden
}

.sidebar-item-toggle.has-image {
  display: none !important
}

.zone-line .sidebar-line-options, .guild-line .sidebar-line-options {
  padding-left: 15px
}

.zone-line .sidebar-line-options.has-zone-image {
  padding-left: 0
}

.sidebar-item.open .sidebar-item-toggle, .sidebar-item:hover .sidebar-item-toggle {
  visibility: visible
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar ul ul {
  margin-left: 25px !important;
}

.sidebar-list {
  position: relative
}

.sidebar-submenu-contents {
  overflow: hidden;
  height: 0;
  transition: height;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  margin-left: 14px !important;
}

.sidebar-line.open + .sidebar-submenu-contents {
  height: auto;
}

.sidebar-line .sidebar-item-toggle > .zmdi-plus {
  display: inline
}

.sidebar-line .sidebar-item-toggle > .zmdi-minus {
  display: none
}

.sidebar-line.open .sidebar-item-toggle > .zmdi-plus {
  display: none
}

.sidebar-line.open .sidebar-item-toggle > .zmdi-minus {
  display: inline
}

.sidebar.left-bar {
  width: 450px;
  max-width: 100%;
  left: 0;
  -webkit-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0);
  box-shadow: 0 0 30px #0E1215;
}

.sidebar.right-bar {
  width: 400px;
  max-width: 100%;
  right: 0;
  -webkit-transform: translate3d(110%, 0, 0);
  transform: translate3d(110%, 0, 0);
  box-shadow: 0 0 30px #0E1215;
}

.sidebar.open {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .sidebar {
    width: 100% !important;
  }
}

.sidebar-header {
  position: relative;
  clear: both;
  font-size: 20px;
  color: white;
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  padding: 5px 15px;
  background-color: #202020;
}

#content-and-footer {
  transition: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}

#content-and-footer.open {
  opacity: 0.4
}

.sidebar-line {
  color: rgb(174, 187, 192) !important;
  transition: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}

.sidebar-line:hover {
  color: white !important
}

.sidebar-line-bg {
  background-position: 100% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20%;
  opacity: 0.75
}

.boss-line-bg {
  background-position: 0 0;
  background-size: 100% auto
}

.sidebar-line:hover .sidebar-line-bg {
  opacity: 0.99
}

.sidebar-line {
  display: block;
  clear: left;
}

.sidebar-line-contents {
  position: relative;
  line-height: 25px;
  overflow: hidden
}

.left-bar .sidebar-line-contents {
  margin-left: 15px;
}

.zone-line {
  font-size: 20px;
}

.boss-line {
  font-size: 16px;
}

.zone-line .sidebar-line-options {
  padding-left: 15px
}

.sidebar-line-option {
  display: inline-block;
  padding: 0;
  margin-right: 12px;
  font-size: 12px !important;
  color: inherit !important;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
}

.sidebar-line-option:hover {
  color: white;
  background-color: #101060;
  border-radius: 5px 5px;
}

.sidebar-line-text {
  margin-left: 5px;
}

.sidebar-zone-icon {
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 10px;
  border: 1px solid #555555;
}

.sidebar-boss-icon {
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 10px;
  border: 1px solid #555555;
}

@media (max-width: 450px) {
  .sidebar-line-options {
    display: none
  }
  .sidebar-zone-icon {
    width: 24px;
    height: 24px;
  }
  .sidebar-boss-icon {
    width: 24px;
    height: 24px;
  }

}

.profile-image {
  height: 42px;
  margin-top: 5px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
}

#acc-toggle {
  cursor: pointer;
  padding: 0 5px
}

#acc-toggle:hover {
  background-color: #002040
}

.sidebar-profile {
  padding: 5px;
  background-color: #202020;
  position: relative;
  line-height: 1.6;
  border-bottom: 1px solid #555555
}

.sidebar-profile-image {
  border-radius: 50%;
  width: 52px;
  float: left;
  margin-right: 10px
}

.sidebar-profile-name {
  font-size: 18px;
}

.sidebar-guild-options {
  position: absolute;
  color: white;
  margin: 5px 10px;
  right: 0;
}

.sidebar-profile-options {
  top: 0;
}

.sidebar-guild-options {
  bottom: 0;
}

.sidebar-profile-options {
  font-size: 19px
}

.sidebar-profile-option, .sidebar-guild-option {
  float: left;
  margin-right: 10px;
  cursor: pointer;
  color: white !important;
}

.sidebar-profile-option {
  margin-top: 2px
}

.sidebar-profile-option:hover {
  transform: scale(1.6);
}

.sidebar-guilds, .sidebar-characters {
  border-bottom: 1px solid #555555;
}

.sidebar-guild-bg {
  display: flex;
  align-items: center;
  justify-content: center
}

.sidebar-guild-icon {
  height:50px;
}

.sidebar-character-icon {
  width:48px;
  height:48px;
  border: 1px solid #555555;
}

.custom-guild-icon {
  border-radius: 100%;
}

.sidebar-guild-commands {
  float: right;
  margin-top: 6px;
}

.logout-button, .sidebar-guild-command {
  font-size: 13px;
  padding: 6px;
  color: white !important;
  border-left: 1px solid #555555;
  border-top: 1px solid #555555;
  background-color: #252525;
}

.sidebar-guild-command {
  float: left;
  margin-left: -1px;
}

.sidebar-report-details {
  font-size: 12px;
  color: #808080;
}

.logout-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.logout-button:hover, .sidebar-guild-command:hover {
  background-color: #002040
}

.dashboard-character-row {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 3px;
  max-width: 100%;
  white-space: nowrap;

  &__text {
    color: $text-gray !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.sidebar-character-class {
  font-size: 13px
}

.ff-character {
  color: #a0a0a0;
}

@media (max-width: 340px) {
  .sidebar-line {
    padding-right: 0 !important
  }
  .sidebar-line-bg {
    display: none
  }
}

.dashboard-container {
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0.7);
}

#report-header-bar {
  font-size: 18px;
  background-color: #001520;
  border-bottom: 1px solid #555555;
}

#report-header-bar-inset {
  max-width: 1024px;
  margin: auto;
  position: relative;
}

.large-icon {
  width: 32px;
  height: 32px;
  --size: 32;
  float: left;
  margin-right: 6px;
}

.tiny-icon {
  width: 14px;
  height: 14px;
  --size: 14;
  margin-right: 5px;
  vertical-align: -2px
}

.disclosure {
  float: right;
  margin: 0;
  font-size: 18px !important;
  color: white;
  padding-left: 12px;
  position: relative
}

// Shared by comparison searches in reports and by rankings.
td.guild-name, td.server-name {
  padding-left: 2px !important
}

.talents-and-gear {
  margin: 10px;
  padding: 5px;
  background-color: black
}

.rankings-talents, .rankings-gear {
  float: left;
  min-width: 200px
}

.rankings-talents-header, .rankings-gear-header {
  font-size: 24px;
  margin-bottom: 10px;
}

.rankings-talent-image, .rankings-gear-image {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 8px;
  margin-bottom: 5px
}

.rankings-talent-row, .rankings-gear-row {
  line-height: 20px;
  clear: left;
  border: none !important;
}

.rankings-profile-image {
  float: left;
  width: 64px;
  height: 64px;
  margin-right: 25px
}

.brackets-table {
  font-size: 11px;
  border-spacing: 3px;
  border-collapse: collapse;
}

.brackets-table td {
  border: 1px solid #555555;
  padding: 2px 4px;
  text-align: center;
  min-width: 45px;
  white-space: nowrap !important
}

.brackets-table td a {
  white-space: nowrap !important
}

.brackets-table td:hover {
  background-color: #002040;
}

.affix-bracket-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  margin-left: 2px
}

.klvl-value {
  display: inline-block;
  text-align: right;
  width: 18px;
}

.keystone-cell {
  width: 112px;
}

.affix-table-icon {
  width: 16px;
  height: 16px;
  border: 1px solid #555555;
}

.affix-table-icon-container {
  width: 112px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center
}

.keystone-score {
  font-weight: normal !important;
  width: 66px;
  min-width: 66px;
  white-space: nowrap;
  text-align: right;
}

.medal-icon {
  width: 20px;
  height: 20px;
  float: left
}

.patch-cell, .ilvl-cell {
  width: 36px;
  text-align: right;
}


/* SmartMenus Core CSS (it's not recommended editing this)
===============================================================*/

.sm, .sm ul, .sm li {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: normal;
  direction: ltr;
}

ul.sm li {
  position: relative;
}

ul.sm a {
  position: relative;
  display: block;
}

ul.sm a.disabled {
  cursor: default;
}

ul.sm ul {
  position: absolute;
  top: -999999px;
  left: -800px;
  width: 100px;
}

ul.sm li {
  float: left;
}

ul.sm-rtl {
  direction: rtl;
}

ul.sm-rtl li {
  float: right;
}

ul.sm ul li, ul.sm-vertical li {
  float: none;
}

ul.sm a {
  white-space: nowrap;
}

ul.sm ul a, ul.sm-vertical a {
  white-space: normal;
}

* html ul.sm-vertical li {
  float: left;
  width: 100%;
}

* html ul.sm-vertical ul li {
  float: none;
  width: auto;
}

*:first-child + html ul.sm-vertical > li {
  float: left;
  width: 100%;
}

ul.sm ul.sm-nowrap > li > a {
  white-space: nowrap;
}

ul.sm:after {
  content: "\00a0";
  display: block;
  height: 0;
  font: 0/0 serif;
  clear: both;
  visibility: hidden;
  overflow: hidden;
}

* html ul.sm {
  height: 1px;
}

*:first-child + html ul.sm {
  min-height: 1px;
}

ul.sm li *, ul.sm li *:before, ul.sm li *:after {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

ul.sm {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
---------------------------------------------------------------
  Note that styles you apply to the main menu items are inherited by the sub menus items too.
  If you'd like to avoid this, you could use child selectors (not supported by IE6) - for example:
  .sm-simple > li > a { ... } instead of .sm-black a { ... }
---------------------------------------------------------------*/


/* Menu box
===================*/

.sm-black,
.sm-black ul {
  font-size: 13px;
  background: #161616;
  border: 1px solid #555555;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#161616), to(#0A0A0A));
  background: -webkit-linear-gradient(top, #161616, #0A0A0A);
  background: -moz-linear-gradient(top, #161616, #0A0A0A);
  background: -o-linear-gradient(top, #161616, #0A0A0A);
  background: -ms-linear-gradient(top, #161616, #0A0A0A);
  background: linear-gradient(to bottom, #161616, #0A0A0A);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 1px #000
}


/* Menu items
===================*/

.sm-black a {
  padding: 6px 12px;
  color: #AEBBC0;
  font-size: 13px;
  line-height: 17px;
  font-family: Avenir, sans-serif;
  text-decoration: none;
}

.sm-black a:hover, .sm-black a:focus, .sm-black a:active,
.sm-black a.highlighted {
  background: #002040;
  color: rgb(70, 197, 255);
}

/* current items - add the class manually to some item or check the "markCurrentItem" script option */
.sm-black a.current, .sm-black a.current:hover, .sm-black a.current:focus, .sm-black a.current:active {
  color: rgb(70, 197, 255);
}

.sm-black a.has-submenu {
  padding-right: 25px;
}

.sm-black-vertical a,
.sm-black ul a {
  padding: 6px 12px;
}

.sm-black ul a.has-submenu,
.sm-black-vertical a.has-submenu {
  padding-right: 40px;
}

/* Separators */

.sm-black li.separator {
  border-bottom: 1px solid #333333 !important;
  margin-right: 10px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  min-width: 100% !important;
}

/* Sub menu indicators
===================*/
.sm-black.hidearrow a span.sub-arrow {
  display: none !important;
}

.sm-black a span.sub-arrow {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -1px;
  /* we will use one-side border to create a triangle so that we don't use a real background image, of course, you can use a real image if you like too */
  width: 0;
  height: 0;
  overflow: hidden;
  border-width: 4px; /* tweak size of the arrow */
  border-style: solid dashed dashed dashed;
  border-color: white transparent transparent transparent;
}

.sm-black-vertical a span.sub-arrow,
.sm-black ul a span.sub-arrow {
  right: 15px;
  top: 50%;
  margin-top: -5px;
  border-width: 5px;
  border-style: dashed dashed dashed solid;
  border-color: transparent transparent transparent white;
}

/* Scrolling arrows containers for tall sub menus - test sub menu: "Sub test" -> "more..." -> "more..." in the default download package
===================*/

.sm-black span.scroll-up, .sm-black span.scroll-down {
  position: absolute;
  display: none;
  visibility: hidden;
  overflow: hidden;
  border: solid #bbb;
  border-width: 1px 0;
  background: black;
  height: 20px;
  /* width and position will be automatically set by the script */
}

.sm-black span.scroll-up-arrow, .sm-black span.scroll-down-arrow {
  position: absolute;
  top: -2px;
  left: 50%;
  margin-left: -8px;
  /* we will use one-side border to create a triangle so that we don't use a real background image, of course, you can use a real image if you like too */
  width: 0;
  height: 0;
  overflow: hidden;
  border-width: 8px; /* tweak size of the arrow */
  border-style: dashed dashed solid dashed;
  border-color: transparent transparent white transparent;
}

.sm-black span.scroll-down-arrow {
  top: 6px;
  border-style: solid dashed dashed dashed;
  border-color: white transparent transparent transparent;
}

body.warcraft {
  --header-desktop-height: #{$header-top-height + $header-bottom-height + 40px};

  .DeathKnight, .DeathKnight.do-not-change-color-on-hover:hover {
    color: rgb(77%, 12%, 23%) !important;
  }

  .DeathKnight-border-color {
    border-color: rgb(77%, 12%, 23%) !important;
  }

  .Druid, .Druid.do-not-change-color-on-hover:hover {
    color: rgb(100%, 49%, 4%) !important
  }

  .Druid-border-color {
    border-color: rgb(100%, 49%, 4%) !important;
  }

  .Hunter, .Hunter.do-not-change-color-on-hover:hover {
    color: rgb(67%, 83%, 45%) !important
  }

  .Hunter-border-color {
    border-color: rgb(67%, 83%, 45%) !important;
  }

  .Mage, .Mage.do-not-change-color-on-hover:hover {
    color: rgb(41%, 80%, 94%) !important
  }

  .Mage-border-color {
    border-color: rgb(41%, 80%, 94%) !important;
  }

  .Monk, .Monk.do-not-change-color-on-hover:hover {
    color: #00ff98 !important
  }

  .Monk-border-color {
    border-color: #00ff98 !important;
  }

  .Evoker, .Evoker.do-not-change-color-on-hover:hover {
    color: #33937F !important
  }

  .Evoker-border-color {
    border-color: #33937F !important;
  }

  .Paladin, .Paladin.do-not-change-color-on-hover:hover {
    color: rgb(96%, 55%, 73%) !important
  }

  .Paladin-border-color {
    border-color: rgb(96%, 55%, 73%) !important;
  }

  .Priest, .Priest.do-not-change-color-on-hover:hover {
    color: rgb(100%, 100%, 100%) !important
  }

  .Priest-border-color {
    border-color: rgb(210, 210, 210) !important;
  }

  .Rogue, .Rogue.do-not-change-color-on-hover:hover {
    color: rgb(100%, 96%, 41%) !important
  }

  .Rogue-border-color {
    border-color: rgb(208, 200, 90) !important;
  }

  .Shaman, .Shaman.do-not-change-color-on-hover:hover {
    color: rgb(14%, 35%, 100%) !important
  }

  .Shaman-border-color {
    border-color: rgb(14%, 35%, 100%) !important;
  }

  .Warlock, .Warlock.do-not-change-color-on-hover:hover {
    color: rgb(58%, 51%, 79%) !important
  }

  .Warlock-border-color {
    border-color: rgb(58%, 51%, 79%) !important;
  }

  .Warrior, .Warrior.do-not-change-color-on-hover:hover {
    color: rgb(78%, 61%, 43%) !important
  }

  .Warrior-border-color {
        border-color: rgb(78%, 61%, 43%) !important;
  }

  .DemonHunter, .DemonHunter.do-not-change-color-on-hover:hover {
    color: #a330c9 !important;
  }

  .DemonHunter-border-color {
    border-color: #a330c9 !important;
  }

  .DeathKnight:hover {
    color: rgb(92%, 27%, 38%) !important
  }

  .Druid:hover {
    color: rgb(100%, 64%, 19%) !important
  }

  .Hunter:hover {
    color: rgb(82%, 98%, 60%) !important
  }

  .Mage:hover {
    color: rgb(56%, 95%, 100%) !important
  }

  .Monk:hover {
    color: #00ffb0 !important
  }

  .Evoker:hover {
    color: #55B39F !important
  }

  .Paladin:hover {
    color: rgb(100%, 70%, 88%) !important
  }

  .Priest:hover {
    color: rgb(85%, 85%, 85%) !important
  }

  .Rogue:hover {
    color: rgb(100%, 100%, 56%) !important
  }

  .Shaman:hover {
    color: rgb(29%, 50%, 100%) !important
  }

  .Warlock:hover {
    color: rgb(72%, 66%, 94%) !important
  }

  .Warrior:hover {
    color: rgb(93%, 76%, 58%) !important
  }

  .DemonHunter:hover {
    color: #c350e9
  }

  .DeathKnight-bg {
    background-color: rgb(77%, 12%, 23%) !important;
  }

  .Druid-bg {
    background-color: rgb(100%, 49%, 4%) !important
  }

  .Hunter-bg {
    background-color: rgb(62%, 78%, 42%) !important
  }

  .Mage-bg {
    background-color: rgb(41%, 80%, 94%) !important
  }

  .Monk-bg {
    background-color: #00ff98 !important
  }

  .Evoker-bg {
    background-color: #33937F !important
  }

  .Paladin-bg {
    background-color: rgb(96%, 55%, 73%) !important
  }

  .Priest-bg {
    background-color: rgb(100%, 100%, 100%) !important
  }

  .Rogue-bg {
    background-color: rgb(100%, 96%, 41%) !important
  }

  .Shaman-bg {
    background-color: rgb(14%, 35%, 100%) !important
  }

  .Warlock-bg {
    background-color: rgb(58%, 51%, 79%) !important
  }

  .Warrior-bg {
    background-color: rgb(78%, 61%, 43%) !important
  }

  .DemonHunter-bg {
    background-color: #a330c9 !important;
  }

  .DeathKnight-behind-text {
    background-color: rgb(57%, 10%, 18%) !important;
  }

  .Druid-behind-text {
    background-color: rgb(80%, 39%, 4%) !important
  }

  .Hunter-behind-text {
    background-color: rgb(47%, 63%, 25%) !important
  }

  .Mage-behind-text {
    background-color: rgb(31%, 60%, 74%) !important
  }

  .Monk-behind-text {
    background-color: #00ff98 !important
  }

  .Evoker-behind-text {
    background-color: #33937F !important
  }

  .Paladin-behind-text {
    background-color: rgb(76%, 44%, 58%) !important
  }

  .Priest-behind-text {
    background-color: rgb(55%, 55%, 55%) !important
  }

  .Rogue-behind-text {
    background-color: rgb(50%, 48%, 20%) !important
  }

  .Shaman-behind-text {
    background-color: rgb(11%, 28%, 80%) !important
  }

  .Warlock-behind-text {
    background-color: rgb(46%, 40%, 63%) !important
  }

  .Warrior-behind-text {
    background-color: rgb(62%, 49%, 34%) !important
  }

  .DemonHunter-behind-text {
    background-color: #631089 !important;
  }

  .TricksOfTheTrade, .TricksOfTheTrade.do-not-change-color-on-hover:hover {
    color: #2599be !important;
  }

  .TricksOfTheTrade-bg, .TricksOfTheTrade-behind-text {
    background-color: #2599be !important;
  }

  .TricksOfTheTrade:hover {
    color: #35a9ce !important;
  }

   .UnholyFrenzy, .UnholyFrenzy.do-not-change-color-on-hover:hover {
    color: #2599be !important;
  }

  .UnholyFrenzy-bg, .UnholyFrenzy-behind-text {
    background-color: #2599be !important;
  }

  .UnholyFrenzy:hover {
    color: #35a9ce !important;
  }

  .faction-0 {
    --faction-color: #{$alliance};
    color: var(--faction-color, #{$alliance}) !important;
  }

  .faction-1 {
    --faction-color: #{$horde};
    color: var(--faction-color, #{$horde}) !important;
  }

  .sprite {
    object-fit: cover;
  }
  
  .actor-sprite-Boss {
    object-position: calc(0px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight-Blood {
    object-position: calc(-36px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight-BloodDPS {
    object-position: calc(-72px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight-Frost {
    object-position: calc(-108px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight-Lichborne {
    object-position: calc(-144px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight-Melee {
    object-position: calc(-180px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight-Runeblade {
    object-position: calc(-216px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight-Unholy {
    object-position: calc(-252px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DeathKnight {
    object-position: calc(-288px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DemonHunter-Havoc {
    object-position: calc(-324px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DemonHunter-Vengeance {
    object-position: calc(-360px / 36 * var(--size)) 0px;
  }
  .actor-sprite-DemonHunter {
    object-position: calc(-396px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Druid-Balance {
    object-position: calc(-432px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Druid-Dreamstate {
    object-position: calc(-468px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Druid-Feral {
    object-position: calc(-504px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Druid-Guardian {
    object-position: calc(-540px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Druid-Restoration {
    object-position: calc(-576px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Druid-Warden {
    object-position: calc(-612px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Druid {
    object-position: calc(-648px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Evoker-Augmentation {
    object-position: calc(-684px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Evoker-Devastation {
    object-position: calc(-720px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Evoker-Preservation {
    object-position: calc(-756px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Evoker-Ranged {
    object-position: calc(-792px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Evoker {
    object-position: calc(-828px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Hunter-BeastMastery {
    object-position: calc(-864px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Hunter-Marksmanship {
    object-position: calc(-900px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Hunter-Melee {
    object-position: calc(-936px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Hunter-Ranged {
    object-position: calc(-972px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Hunter-Survival {
    object-position: calc(-1008px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Hunter {
    object-position: calc(-1044px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Mage-Arcane {
    object-position: calc(-1080px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Mage-Fire {
    object-position: calc(-1116px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Mage-Frost {
    object-position: calc(-1152px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Mage-Healer {
    object-position: calc(-1188px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Mage-Ranged {
    object-position: calc(-1224px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Mage {
    object-position: calc(-1260px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Monk-Brewmaster {
    object-position: calc(-1296px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Monk-Mistweaver {
    object-position: calc(-1332px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Monk-Windwalker {
    object-position: calc(-1368px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Monk {
    object-position: calc(-1404px / 36 * var(--size)) 0px;
  }
  .actor-sprite-NPC {
    object-position: calc(-1440px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Paladin-Holy {
    object-position: calc(-1476px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Paladin-Justicar {
    object-position: calc(-1512px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Paladin-Protection {
    object-position: calc(-1548px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Paladin-Retribution {
    object-position: calc(-1584px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Paladin {
    object-position: calc(-1620px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Pet {
    object-position: calc(-1656px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Priest-Discipline {
    object-position: calc(-1692px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Priest-Healing {
    object-position: calc(-1728px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Priest-Holy {
    object-position: calc(-1764px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Priest-Shadow {
    object-position: calc(-1800px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Priest-Smiter {
    object-position: calc(-1836px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Priest {
    object-position: calc(-1872px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Rogue-Assassination {
    object-position: calc(-1908px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Rogue-Combat {
    object-position: calc(-1944px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Rogue-Melee {
    object-position: calc(-1980px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Rogue-Outlaw {
    object-position: calc(-2016px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Rogue-Subtlety {
    object-position: calc(-2052px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Rogue-Tank {
    object-position: calc(-2088px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Rogue {
    object-position: calc(-2124px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Shaman-Elemental {
    object-position: calc(-2160px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Shaman-Enhancement {
    object-position: calc(-2196px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Shaman-Restoration {
    object-position: calc(-2232px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Shaman-Tank {
    object-position: calc(-2268px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Shaman {
    object-position: calc(-2304px / 36 * var(--size)) 0px;
  }
  .actor-sprite-TricksOfTheTrade {
    object-position: calc(-2340px / 36 * var(--size)) 0px;
  }
  .actor-sprite-UnholyFrenzy {
    object-position: calc(-2376px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warlock-Affliction {
    object-position: calc(-2412px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warlock-Demonology {
    object-position: calc(-2448px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warlock-Destruction {
    object-position: calc(-2484px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warlock-Ranged {
    object-position: calc(-2520px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warlock-Tank {
    object-position: calc(-2556px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warlock {
    object-position: calc(-2592px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior-Arms {
    object-position: calc(-2628px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior-Champion {
    object-position: calc(-2664px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior-Fury {
    object-position: calc(-2700px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior-Gladiator {
    object-position: calc(-2736px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior-Melee {
    object-position: calc(-2772px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior-Protection {
    object-position: calc(-2808px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior-Warden {
    object-position: calc(-2844px / 36 * var(--size)) 0px;
  }
  .actor-sprite-Warrior {
    object-position: calc(-2880px / 36 * var(--size)) 0px;
  }
}


.simple-tooltip-container {
  position: relative;
}

.simple-tooltip-content {
  display: none;
  position: absolute;
  top: 75%;
  right: 10%;
  width: 200px;
  background-color: black;
  z-index: 99;
  padding: 0.75rem;
  flex-direction: column;
  border: 1px solid #555555;
  white-space: normal;
}

.simple-tooltip-content:hover {
  display: flex;
}

.simple-tooltip-visible-content:hover + .simple-tooltip-content {
  display: flex;
}

.stat-haste {
  color: #fbf934;
}

.stat-crit {
  color: #fd773c;
}

.stat-mastery {
  color: #b178dd;
}

.stat-versatility {
  color: #4faeff;
}

.DeathKnight-gradient-bg {
  background: linear-gradient(93deg, #EB425E 0%, #AB0C27 100%);
}

.DeathKnight-gradient-bg-dark {
  background: linear-gradient(93deg, rgba(235, 66, 94, 0.10) 0%, rgba(171, 12, 39, 0.10) 100%), #000;
}

.DemonHunter-gradient-bg {
  background: linear-gradient(91deg, #BB45E1 0%, #891EAC 100%);
}

.DemonHunter-gradient-bg-dark {
  background: linear-gradient(91deg, rgba(187, 69, 225, 0.10) 0%, rgba(137, 30, 172, 0.10) 100%), #000;
}

.Druid-gradient-bg {
  background: linear-gradient(91deg, #FC8A26 0%, #E36E06 100%);
}

.Druid-gradient-bg-dark {
  background: linear-gradient(91deg, rgba(252, 138, 38, 0.10) 0%, rgba(227, 110, 6, 0.10) 100%), #000;
}

.Evoker-gradient-bg {
  background: linear-gradient(92deg, #23957D 0%, #0B6956 100%);
}

.Evoker-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(35, 149, 125, 0.10) 0%, rgba(11, 105, 86, 0.10) 100%), #000;
}

.Hunter-gradient-bg {
  background: linear-gradient(92deg, #8BA962 0.03%, #6F9A34 100.03%);
}

.Hunter-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(139, 169, 98, 0.10) 0.03%, rgba(111, 154, 52, 0.10) 100.03%), #000;
}

.Mage-gradient-bg {
  background: linear-gradient(92deg, #4FB7DD 0.04%, #207D9F 100.04%);
}

.Mage-gradient-bg-dark  {
  background: linear-gradient(92deg, rgba(79, 183, 221, 0.10) 0.04%, rgba(32, 125, 159, 0.10) 100.04%), #000;
}

.Monk-gradient-bg {
  background: linear-gradient(92deg, #52C09D 0.04%, #53DA99 0.05%, #46AF70 100.04%);
}

.Monk-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(82, 192, 157, 0.10) 0.04%, rgba(83, 218, 153, 0.10) 0.05%, rgba(70, 175, 112, 0.10) 100.04%), #000;
}

.Paladin-gradient-bg {
  background: linear-gradient(92deg, #FAA2C9 0%, #DC5D95 100%);
}

.Paladin-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(250, 162, 201, 0.10) 0%, rgba(220, 93, 149, 0.10) 100%), #000;
}

.Priest-gradient-bg {
  background: linear-gradient(92deg, #4d4d4d 0%, #d3d3d3 100%);
}

.Priest-gradient-bg-dark {
  background: linear-gradient(0deg, rgba(222, 222, 222, 0.10) 0%, rgba(222, 222, 222, 0.10) 100%), #000;
}

.Rogue-gradient-bg {
  background: linear-gradient(92deg, #8b8744 0%, #C3B816 100%);
}

.Rogue-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(228, 221, 117, 0.10) 0%, rgba(195, 184, 22, 0.10) 100%), #000;
}

.Shaman-gradient-bg {
  background: linear-gradient(92deg, #4571FC 0%, #0336D6 100%);
}

.Shaman-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(69, 113, 252, 0.10) 0%, rgba(3, 54, 214, 0.10) 100%), #000;
}

.Warlock-gradient-bg {
  background: linear-gradient(92deg, #A58FE8 0%, #654BB5 100%);
}

.Warlock-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(165, 143, 232, 0.10) 0%, rgba(101, 75, 181, 0.10) 100%), #000;
}

.Warrior-gradient-bg {
  background: linear-gradient(92deg, #BA8C5B 0%, #8D6030 100%);
}

.Warrior-gradient-bg-dark {
  background: linear-gradient(92deg, rgba(186, 140, 91, 0.10) 0%, rgba(141, 96, 48, 0.10) 100%), #000;
}

.encounter-61501-gradient-bg,
.encounter-62520-gradient-bg,
.encounter-10670-gradient-bg,
.encounter-12648-gradient-bg {
  background: linear-gradient(92deg, #A58FE8 0%, #654BB5 100%);
}

.encounter-61501-border-color,
.encounter-62520-border-color,
.encounter-10670-border-color,
.encounter-12648-border-color {
  border-color: rgb(58%, 51%, 79%) !important;
}

.encounter-61763-gradient-bg,
.encounter-62451-gradient-bg,
.encounter-12649-gradient-bg {
  background: linear-gradient(92deg, #8b8744 0%, #C3B816 100%);
}

.encounter-61763-border-color,
.encounter-62451-border-color,
.encounter-12649-border-color {
  border-color: rgb(208, 200, 90) !important;
}

.encounter-12579-gradient-bg,
.encounter-12580-gradient-bg,
.encounter-62527-gradient-bg,
.encounter-11822-gradient-bg,
.encounter-12661-gradient-bg {
  background: linear-gradient(91deg, #dc8d1e 0%, #c67606 100%);
}

.encounter-12579-border-color,
.encounter-12580-border-color,
.encounter-62527-border-color,
.encounter-11822-border-color,
.encounter-12661-border-color {
  border-color: rgb(227, 129, 8) !important;
}

.encounter-61862-gradient-bg,
.encounter-62526-gradient-bg,
.encounter-12290-gradient-bg,
.encounter-12773-gradient-bg {
  background: linear-gradient(92deg, #4571FC 0%, #0336D6 100%);
}

.encounter-61862-border-color,
.encounter-62526-border-color,
.encounter-12290-border-color,
.encounter-12773-border-color {
  border-color: rgb(14%, 35%, 100%) !important;
}

.encounter-61466-gradient-bg,
.encounter-62521-gradient-bg,
.encounter-62519-gradient-bg,
.encounter-12669-gradient-bg {
  background: linear-gradient(93deg, #EB425E 0%, #AB0C27 100%);
}

.encounter-61466-border-color,
.encounter-62521-border-color,
.encounter-62519-border-color,
.encounter-12669-border-color {
  border-color: rgb(77%, 12%, 23%) !important;
}

.encounter-10643-gradient-bg,
.encounter-62515-gradient-bg,
.encounter-12098-gradient-bg {
  background: linear-gradient(92deg, #4FB7DD 0.04%, #207D9F 100.04%);
}

.encounter-10643-border-color,
.encounter-62515-border-color,
.encounter-12098-border-color {
  border-color: rgb(41%, 80%, 94%) !important;
}

.encounter-61279-gradient-bg,
.encounter-62516-gradient-bg,
.encounter-12286-gradient-bg,
.encounter-12293-gradient-bg {
  background: linear-gradient(92deg, #8BA962 0.03%, #6F9A34 100.03%);
}

.encounter-61279-border-color,
.encounter-62516-border-color,
.encounter-12286-border-color,
.encounter-12293-border-color {
  border-color: rgb(67%, 83%, 45%) !important;
}

.encounter-12662-gradient-bg,
.encounter-11594-gradient-bg {
  background: linear-gradient(92deg, #BA8C5B 0%, #8D6030 100%);
}

.encounter-12662-border-color,
.encounter-11594-border-color {
  border-color: rgb(78%, 61%, 43%) !important;
}

.encounter-12660-gradient-bg,
.encounter-12651-gradient-bg {
  background: linear-gradient(91deg, #BB45E1 0%, #891EAC 100%);
}

.encounter-12660-border-color,
.encounter-12651-border-color {
  border-color: #a330c9 !important;
}

// stonevault
.encounter-12652-border-color {
  border-color: #9f3e29 !important;
}

.encounter-12652-gradient-bg {
  background: linear-gradient(92deg, #b03d0d 0.04%, #8b301f 100%);
}
